import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useFormik, Form, FormikProvider } from 'formik'
// material
import { Stack, Alert, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// hooks
import useAuth from '../../../hooks/useAuth'
import useQuery from '../../../hooks/useQuery'
import useIsMountedRef from '../../../hooks/useIsMountedRef'
// routes
import { PATH_AUTH, PATH_SECURED } from '../../../routes/paths'
// utils
import { saveValue, removeValue, KEYS } from '../../../utils/handleLocalStorage'
import { log } from '../../../utils/logger'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, ssoLogin } = useAuth()
  const isMountedRef = useIsMountedRef()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const query = useQuery()

  /**
   * Determine the query parameters and take action
   */
  useEffect(() => {
    // Prepare the exchange function
    async function exchangeCode(code) {
      try {
        const payload = { code }
        await ssoLogin(payload)
        removeValue(KEYS.EMAIL)
        enqueueSnackbar('Verify success', { variant: 'success' })
        navigate(PATH_SECURED.root)
      } catch (error) {
        log.error(error)
        navigate(PATH_AUTH.signup)
      }
    }
    // Check if we have a code to exchange
    const ssoCode = query.get('code')
    if (ssoCode) {
      exchangeCode(ssoCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        saveValue(KEYS.EMAIL, values.email)
        const result = await login(values)
        // Remove submitting
        if (isMountedRef.current) {
          setSubmitting(false)
        }
        // Check SSO stuff
        if (result?.response_objects?.sso && result?.response_objects?.sso_redirect_url) {
          // Redirect the user to the SSO IDP page
          window.location.href = result.response_objects.sso_redirect_url
        } else {
          navigate(PATH_AUTH.verify)
        }
      } catch (error) {
        log.error(error)
        navigate(PATH_AUTH.signup)
      }
    }
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <span />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="secondary"
          loading={isSubmitting}
          sx={{ boxShadow: 'none' }}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}
