import api from './api'

// get
export const getSpaceById = (spaceId) => api.get(`spaces/${spaceId}`)

export const getSpaceMembers = (spaceId) => api.get(`spaces/${spaceId}/members`) //  ?page=${page}&limit=${limit}`)

export const getSpaceAnalytics = (spaceId) => api.get(`/analytics/spaces/${spaceId}`)

export const getSpaceTopics = (spaceId) => api.get(`spaces/${spaceId}/conversation_topics`)

export const getSpaceQuestions = (spaceId) => api.get(`spaces/${spaceId}/conversation_questions`)

export const getSpaceRooms = (spaceId) => api.get(`rooms/spaces/${spaceId}`)

// post
export const createSpace = (space) => api.post('spaces', space)

export const createSpaceTopic = (spaceId, topic) => api.post(`spaces/${spaceId}/conversation_topics`, topic)

export const createSpaceQuestion = (spaceId, question) => api.post(`spaces/${spaceId}/conversation_questions`, question)

export const createSpaceGroup = (spaceId, groups) => api.post(`spaces/${spaceId}/groups`, groups)

// put

export const updateSpaceName = (payload, spaceId) => api.put(`spaces/${spaceId}`, payload)

export const updateSpaceTopic = (spaceId, id, topic) => api.put(`spaces/${spaceId}/conversation_topics/${id}`, topic)

export const updateSpaceQuestion = (spaceId, id, question) =>
  api.put(`spaces/${spaceId}/conversation_questions/${id}`, question)

export const deleteSpace = (spaceId, payload) => api.put(`spaces/${spaceId}`, payload)
