import api from './api'
import assetAPI from './assetAPI'

// get
export const getUserById = (userId) => api.get(`users/${userId}`)

export const getUserNotifications = (userId) => api.get(`users/${userId}/notifications`)

export const getUserConnections = (userId) => api.get(`users/${userId}/connections`)
// put
export const updateUserInfo = (userId, payload) => api.put(`users/${userId}`, payload)

// ASSET API
// put
export const uploadProfilePicture = (userId, payload) => assetAPI.put(`/users/${userId}/profile_picture`, payload)

export const uploadBackgroundPicture = (userId, payload) => assetAPI.put(`/users/${userId}/background_picture`, payload)

// Delete
export const deleteBackgroundPicture = (userId) => assetAPI.delete(`/users/${userId}/background_picture`)
