import api from './api'

// post
export const googleOauthLogin = (payload) => api.post('/users/oauth_login', payload)

export const linkedInOauthLogin = (payload) => api.post('/users/oauth_login_linkedin', payload)

export const ssoCallbackLogin = (payload) => api.post('/auth/sso/callback', payload)

export const twineSignup = (payload) => api.post('/users', payload)

export const twineLogin = (payload) => api.post('/users/login', payload)

export const refreshToken = (payload) => api.post('/users/refresh_token', payload)

// put
export const resetPassword = (payload) => api.put('/users/reset_password', payload)
