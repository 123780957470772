import { createSlice } from '@reduxjs/toolkit'
// services
import { getUserNotifications } from '../../services/user'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getNotificationsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getNotifications(userId) {
  const { startLoading, hasError, getNotificationsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getUserNotifications(userId)
      dispatch(getNotificationsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}
