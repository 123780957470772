import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
// components
import SpaceCard from './SpaceCard'

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  minHeight: 600,
  rowGap: theme.spacing(3),
  columnGap: theme.spacing(3),
  padding: theme.spacing(3)
}))

export default function SpacesGrid({ spaces, loadSpace, onOpenManager, page, rowsPerPage, user }) {
  return (
    <RootStyle>
      {spaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((space, index) => (
        <SpaceCard key={index} space={space} loadSpace={loadSpace} user={user} onOpenManager={onOpenManager} />
      ))}
    </RootStyle>
  )
}

SpacesGrid.propTypes = {
  spaces: PropTypes.array,
  loadSpace: PropTypes.func,
  onOpenManager: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  user: PropTypes.object
}
