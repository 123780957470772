import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  alt: PropTypes.string
}

export default function Logo({ sx, alt }) {
  return (
    <Box sx={{ width: 32, height: 32, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>{alt}</title>
        <g transform="translate(.156 .156)" fill="none">
          <circle fill="#4A2BC7" cx="14.844" cy="14.844" r="14.844" />
          <path
            d="M17.144 20.168c-.697 0-1.266-.614-1.266-1.486V13.19h3.057v-3.064h-3.057V6.526h-3.617v3.6h-1.835v3.063h1.835v6.083c0 2.383 1.654 4.048 4.005 4.048 1.085 0 2.3-.461 3.075-1.153l-.905-2.434c-.258.256-.775.435-1.292.435z"
            fill="#FFF"
          />
        </g>
      </svg>
    </Box>
  )
}
