import { createSlice } from '@reduxjs/toolkit'
// services
import { getUserById } from '../../services/user'
// redux
import { getMemberConnections } from './memberConnections'
// utils
import { log } from '../../utils/logger'
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getMemberSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getMember(memberId) {
  const { startLoading, hasError, getMemberSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getUserById(memberId)
      dispatch(getMemberSuccess(data.response_objects))
      dispatch(getMemberConnections(memberId))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}
