import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import searchFill from '@iconify/icons-eva/search-fill'
// material
import { styled } from '@mui/material/styles'
import { Box, Toolbar, OutlinedInput, InputAdornment } from '@mui/material'

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 340,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}))

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterSearch: PropTypes.func,
  placeholder: PropTypes.string
}

export default function ListToolbar({ filterName, onFilterSearch, placeholder }) {
  return (
    <RootStyle>
      <SearchStyle
        value={filterName}
        onChange={onFilterSearch}
        placeholder={placeholder || 'Search...'}
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  )
}
