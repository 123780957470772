import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer'
import useUser from '../../hooks/useUser'
//
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
// Components
import Intercom from '../../components/intercom/Intercom'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme()
  const { collapseClick } = useCollapseDrawer()
  const [open, setOpen] = useState(false)
  const { data: user } = useUser()
  const [intercomUser, setIntercomUser] = useState({
    user_id: '',
    email: '',
    name: ''
  })

  useEffect(() => {
    setIntercomUser({
      user_id: user.id,
      email: user.email,
      name: `${user.first_name} ${user.last_name}`
    })
  }, [user])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
      {intercomUser.user_id && <Intercom appID={process.env.REACT_APP_INTERCOM_TOKEN} {...intercomUser} />}
    </RootStyle>
  )
}
