import { useState, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
// hooks
import useAuth from '../hooks/useAuth'
// pages
import Login from '../pages/authentication/Login'
// components
import LoadingScreen from '../components/LoadingScreen'
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default function AuthGuard({ children }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isInitialized, isAuthenticated, authJWT } = useAuth()
  const [requestedLocation, setRequestedLocation] = useState(null)

  useEffect(() => {
    authJWT().then((path) => navigate(path))
  }, []) // eslint-disable-line

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    // user HAS NOT logged in yet

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}
