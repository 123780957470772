import { createSlice } from '@reduxjs/toolkit'
// services
import { getSpaceById, createSpaceGroup, updateSpaceName, createSpace, deleteSpace } from '../../services/space'
// redux
import { getMembers } from './members'
import { getTopics } from './topics'
import { getQuestions } from './questions'
import { getRooms } from './rooms'
import { getUser, refreshUserData } from './user'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {},
  demoSpace: {},
  spaceData: {}
}

const slice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getSpaceSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    },

    // SUCCESS -> STORE DATA
    getDemoSpaceSuccess(state, action) {
      state.demoSpace = action.payload
    },

    reset(state) {
      state.data = {}
    },

    getDataById(state, action) {
      state.spaceData = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getSpace(spaceId) {
  const { startLoading, hasError, getSpaceSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getSpaceById(spaceId)
      dispatch(getSpaceSuccess(data.response_objects))
      dispatch(getMembers(spaceId))
      dispatch(getTopics(spaceId))
      dispatch(getQuestions(spaceId))
      dispatch(getRooms(spaceId))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function refreshSpace(spaceId) {
  const { hasError, getSpaceSuccess } = slice.actions
  return async (dispatch) => {
    try {
      const data = await getSpaceById(spaceId)
      dispatch(getSpaceSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function createGroup(groups, spaceId) {
  const { getSpaceSuccess, hasError } = slice.actions
  return async (dispatch) => {
    try {
      await createSpaceGroup(spaceId, groups)
      const data = await getSpaceById(spaceId)
      dispatch(getSpaceSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function updateSpace(space, userId) {
  const { hasError } = slice.actions
  return async (dispatch) => {
    const spaceId = space.id
    const payload = {
      name: space.name,
      description: space.description,
      feature_ids: space.feature_ids
    }
    try {
      await updateSpaceName(payload, spaceId)
      dispatch(refreshUserData(userId))
      dispatch(refreshSpace(spaceId))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function addSpace(space, userId) {
  const { hasError } = slice.actions
  return async (dispatch) => {
    try {
      const data = await createSpace(space)
      dispatch(refreshUserData(userId))
      dispatch(getSpace(data.response_objects.id))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function addDemoSpace(space) {
  const { getDemoSpaceSuccess, hasError } = slice.actions
  return async (dispatch) => {
    try {
      const data = await createSpace(space)
      dispatch(getDemoSpaceSuccess(data.response_objects))
      dispatch(getQuestions(data.response_objects.id))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function reset() {
  const { reset } = slice.actions
  return async (dispatch) => {
    dispatch(reset())
  }
}

export function removeSpace(spaceId, userId) {
  return async (dispatch) => {
    const payload = {
      is_disabled: true
    }
    await deleteSpace(spaceId, payload)
    dispatch(getUser(userId))
  }
}

export function fetchSpaceData(spaceId) {
  const { getDataById } = slice.actions
  return async (dispatch) => {
    const data = await getSpaceById(spaceId)
    dispatch(getDataById(data.response_objects))
  }
}
