import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'
// componantes
import SpacesGrid from './SpacesGrid'
import SpacesFooter from './SpacesFooter'
// hooks
import useUser from '../../hooks/useUser'

// constants

Spaces.propTypes = {
  records: PropTypes.array,
  loadSpace: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  onOpenManager: PropTypes.func,
  onOpenCreateSpace: PropTypes.func
}

export default function Spaces({
  records,
  loadSpace,
  rowsPerPage,
  page,
  onPageChange,
  onChangeRowsPerPage,
  setRowsPerPage,
  onOpenManager,
  onOpenCreateSpace
}) {
  const { data: user } = useUser()
  const adminSpace = records.filter((space) => user.admin_space_ids.includes(space.id)) // Only spaces current user is Admin
  return (
    <Box>
      <SpacesGrid
        rowsPerPage={rowsPerPage}
        page={page}
        spaces={adminSpace}
        loadSpace={loadSpace}
        onOpenManager={onOpenManager}
        onOpenCreateSpace={onOpenCreateSpace}
        user={user}
      />
      <SpacesFooter
        records={adminSpace}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Box>
  )
}
