import CryptoJS from 'crypto-js'
import { log } from './logger'

const secret = process.env.REACT_APP_CRYPTOJS_SECRET

export const encryptData = (data) => {
  try {
    return CryptoJS.AES.encrypt(data.toString(), secret).toString()
  } catch (error) {
    log.error(error)
    return null
  }
}

export const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secret)
    return bytes.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    log.error(error)
    return null
  }
}
