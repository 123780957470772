import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { log } from '../../utils/logger'

const canUseDOM = !!window?.document?.createElement

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args)
  } else {
    log.warning('Intercom not initialized yet')
  }
}

Intercom.propTypes = {
  appID: PropTypes.string.isRequired
}

/**
 * This is a wrapper over Intercom JS code to initialize the
 * intercom chat
 * @param {string} appID - The App ID from intercom
 */
export default function Intercom({ appID, ...otherProps }) {
  /**
   * Initialization
   */
  useEffect(() => {
    if (appID && canUseDOM) {
      const scriptSrc = `https://widget.intercom.io/widget/${appID}`
      if (!window.Intercom && !isScriptLoaded(scriptSrc)) {
        ;(function a1(w, d, id, s, x, l) {
          function i() {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments)
          }
          i.q = []
          i.c = function a2(args) {
            i.q.push(args)
          }
          w.Intercom = i
          l = function a3() {
            log.info('Installing intercom')
            s = d.createElement('script')
            s.async = true
            s.src = scriptSrc
            d.head.appendChild(s)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        })(window, document, appID)
      }
      window.intercomSettings = { app_id: appID, ...otherProps }

      if (window.Intercom) {
        const extra = window.intercomSettings
        log.info('Booting Intercom')
        window.Intercom('shutdown')
        window.Intercom('boot', extra)
      }
    }
  })

  /**
   * Determine if the script is already loaded in the document
   * @param {string} url - Script URL
   */
  const isScriptLoaded = (url) => {
    const scripts = document.getElementsByTagName('script')
    // eslint-disable-next-line no-plusplus
    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === url) return true
    }
    return false
  }

  return false
}
