import { useSelector, useDispatch } from '../redux/store'
import {
  getSpace,
  createGroup,
  updateSpace,
  addSpace,
  addDemoSpace,
  removeSpace,
  fetchSpaceData
} from '../redux/slices/space'

const useSpace = () => {
  const dispatch = useDispatch()

  const load = (id) => dispatch(getSpace(id))

  const createGroups = (groups, id) => dispatch(createGroup(groups, id))

  const updateSpaceName = (space, userId) => dispatch(updateSpace(space, userId))

  const createSpace = (space, userId) => dispatch(addSpace(space, userId))

  const createDemoSpace = (space) => dispatch(addDemoSpace(space))

  const deleteSpace = (spaceId, userId) => dispatch(removeSpace(spaceId, userId))

  const getSpaceData = (spaceId) => dispatch(fetchSpaceData(spaceId))

  const { data, isLoading, isSuccess, error, demoSpace, spaceData } = useSelector((state) => state.space)

  return {
    load,
    createGroups,
    updateSpaceName,
    createSpace,
    createDemoSpace,
    deleteSpace,
    getSpaceData,
    data,
    demoSpace,
    spaceData,
    isLoading,
    isSuccess,
    error
  }
}

export default useSpace
