import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
// hooks
import useAuth from '../hooks/useAuth'
// components
import LoadingScreen from '../components/LoadingScreen'
// routes
import { PATH_SECURED } from '../routes/paths'
// utils
import { getValue, KEYS } from '../utils/handleLocalStorage'

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const { isInitialized, isAuthenticated } = useAuth()

  const checkIsClaimFlow = () => {
    const isClaimFlow = getValue(KEYS.CLAIM_FLOW)
    return isClaimFlow
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    // user has logged in
    if (!checkIsClaimFlow()) {
      // If we are NOT in claim flow then we go
      // to root path
      return <Navigate to={PATH_SECURED.root} />
    }
  }

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}
