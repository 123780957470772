import PropTypes from 'prop-types'
// mat3erial
import { styled } from '@mui/material/styles'
import { Stack } from '@mui/material'
// icons
import { GoogleIcon, GoogleIconWithText, LinkedinIcon, LinkedinIconWithText } from '../../../assets'
// hooks
import useAuth from '../../../hooks/useAuth'

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row'
}))

const StackStyle = styled('div')(() => ({
  width: '148px',
  height: '48px',
  border: 'solid 1px rgba(145, 158, 171, 0.32)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  borderRadius: 8,
  padding: '12px 62px',
  marginRight: 8,
  cursor: 'pointer'
}))

const StackStyleWithText = styled('div')(({ theme }) => ({
  border: 'solid 1px rgba(145, 158, 171, 0.32)',
  height: '48px',
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: 8,
  cursor: 'pointer',
  marginRight: theme.spacing(1),
  fontSize: 15,
  lineHeight: 1.73,
  fontWeight: 'bold'
}))

const DivStyle = styled('div')(() => ({
  textAlign: 'center',
  borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
  lineHeight: '0.1em',
  margin: '24px 0 32px'
}))

const SpanStyle = styled('span')(() => ({
  background: '#fff',
  padding: '0 10px',
  color: '#637381',
  fontSize: '.75em'
}))

/**
 * @param {*} showOr boolean (if false, we hide the -----OR---- line at the bottom)
 * @returns Social Networks Buttons for login/signup
 */
export default function SocialNetworks({ showOr = true, withText = false }) {
  const { googleLogin, linkedInLogin } = useAuth()

  const handleGoogleinLogin = () => googleLogin()
  const handleLinkedinLogin = () => linkedInLogin()

  return (
    <>
      <ContentStyle>
        {withText ? (
          <Stack display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
            <StackStyleWithText onClick={handleLinkedinLogin}>
              <LinkedinIconWithText text="Continue with LinkedIn" />
            </StackStyleWithText>
            <StackStyleWithText onClick={handleGoogleinLogin}>
              <GoogleIconWithText text="Continue with Google" />
            </StackStyleWithText>
          </Stack>
        ) : (
          <Stack display="flex" flexDirection="row" justifyContent="center" mb={1}>
            <StackStyle sx={{ mb: 1 }} onClick={handleGoogleinLogin}>
              <GoogleIcon />
            </StackStyle>
            <StackStyle sx={{ mb: 1 }} onClick={handleLinkedinLogin}>
              <LinkedinIcon />
            </StackStyle>
          </Stack>
        )}
      </ContentStyle>
      {showOr && (
        <DivStyle>
          <SpanStyle>OR</SpanStyle>
        </DivStyle>
      )}
    </>
  )
}

SocialNetworks.propTypes = {
  showOr: PropTypes.bool,
  withText: PropTypes.bool
}
