import { createSlice } from '@reduxjs/toolkit'
// services
import { getUserConnections } from '../../services/user'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getUserConnectionsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    },

    reset(state) {
      state.data = {}
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getConnections(userId) {
  const { startLoading, hasError, getUserConnectionsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getUserConnections(userId)
      dispatch(getUserConnectionsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function reset() {
  const { reset } = slice.actions
  return async (dispatch) => {
    dispatch(reset())
  }
}
