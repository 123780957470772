import * as jose from 'jose'
// utils
import { log } from './logger'

export async function verifyJwt(jwt) {
  const { REACT_APP_AUTH_JWT_KEY } = await process.env
  const secretKey = new TextEncoder().encode(REACT_APP_AUTH_JWT_KEY)

  let refreshToken
  let userId

  try {
    const { payload } = await jose.jwtVerify(jwt, secretKey)
    refreshToken = await payload?.refresh_token
    userId = await payload?.user_id
  } catch (error) {
    log.error(error?.message)
  }

  return { userId, refreshToken }
}

export async function checkJWT() {
  return new Promise((resolve, reject) => {
    const { location } = window

    const { search, pathname } = location
    const urlParams = search.replace('?', '').split('&')
    let path = pathname

    try {
      // Let´s look for the query strings and add them to the final path
      urlParams.forEach(async (param) => {
        const [key, value] = param.split('=')
        if (key !== 'jwt' && !['undefined', 'null'].includes(value)) {
          path += `${path.includes('?') ? '&' : '?'}${key}=${value}`
        }
      })
      // Let's look for the jwt values
      urlParams.forEach(async (param) => {
        const [key, value] = param.split('=')
        if (key === 'jwt' && value) {
          const values = await verifyJwt(value)
          if (values.userId && values.refreshToken) {
            // if we found the userId and refreshToken we store them in localStorage
            // and redirect to the requestes path
            resolve({ ...values, path })
          }
        }
      })
    } catch (error) {
      reject(error)
    }
  })
}
