import { useSelector, useDispatch } from '../redux/store'
import { getUser, updateUser } from '../redux/slices/user'

const useUser = () => {
  const dispatch = useDispatch()

  const load = (id) => dispatch(getUser(id))

  const update = (user, profilePicture, backgroundPicture) =>
    dispatch(updateUser(user, profilePicture, backgroundPicture))

  const { data, isLoading, isSuccess, error } = useSelector((state) => state.user)

  return {
    load,
    update,
    data,
    isLoading,
    isSuccess,
    error
  }
}

export default useUser
